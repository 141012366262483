var exports = {};
exports = jsExtras;
jsExtras.displayName = "jsExtras";
jsExtras.aliases = [];

function jsExtras(Prism) {
  ;

  (function (Prism) {
    Prism.languages.insertBefore("javascript", "function-variable", {
      "method-variable": {
        pattern: RegExp("(\\.\\s*)" + Prism.languages.javascript["function-variable"].pattern.source),
        lookbehind: true,
        alias: ["function-variable", "method", "function", "property-access"]
      }
    });
    Prism.languages.insertBefore("javascript", "function", {
      method: {
        pattern: RegExp("(\\.\\s*)" + Prism.languages.javascript["function"].source),
        lookbehind: true,
        alias: ["function", "property-access"]
      }
    });
    Prism.languages.insertBefore("javascript", "constant", {
      "known-class-name": [{
        // standard built-ins
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects
        pattern: /\b(?:(?:(?:Uint|Int)(?:8|16|32)|Uint8Clamped|Float(?:32|64))?Array|ArrayBuffer|BigInt|Boolean|DataView|Date|Error|Function|Intl|JSON|Math|Number|Object|Promise|Proxy|Reflect|RegExp|String|Symbol|(?:Weak)?(?:Set|Map)|WebAssembly)\b/,
        alias: "class-name"
      }, {
        // errors
        pattern: /\b(?:[A-Z]\w*)Error\b/,
        alias: "class-name"
      }]
    });
    /**
     * Replaces the `<ID>` placeholder in the given pattern with a pattern for general JS identifiers.
     *
     * @param {string} source
     * @param {string} [flags]
     * @returns {RegExp}
     */

    function withId(source, flags) {
      return RegExp(source.replace(/<ID>/g, function () {
        return /(?!\s)[_$a-zA-Z\xA0-\uFFFF](?:(?!\s)[$\w\xA0-\uFFFF])*/.source;
      }), flags);
    }

    Prism.languages.insertBefore("javascript", "keyword", {
      imports: {
        // https://tc39.es/ecma262/#sec-imports
        pattern: withId(/(\bimport\b\s*)(?:<ID>(?:\s*,\s*(?:\*\s*as\s+<ID>|\{[^{}]*\}))?|\*\s*as\s+<ID>|\{[^{}]*\})(?=\s*\bfrom\b)/.source),
        lookbehind: true,
        inside: Prism.languages.javascript
      },
      exports: {
        // https://tc39.es/ecma262/#sec-exports
        pattern: withId(/(\bexport\b\s*)(?:\*(?:\s*as\s+<ID>)?(?=\s*\bfrom\b)|\{[^{}]*\})/.source),
        lookbehind: true,
        inside: Prism.languages.javascript
      }
    });
    Prism.languages.javascript["keyword"].unshift({
      pattern: /\b(?:as|default|export|from|import)\b/,
      alias: "module"
    }, {
      pattern: /\b(?:await|break|catch|continue|do|else|for|finally|if|return|switch|throw|try|while|yield)\b/,
      alias: "control-flow"
    }, {
      pattern: /\bnull\b/,
      alias: ["null", "nil"]
    }, {
      pattern: /\bundefined\b/,
      alias: "nil"
    });
    Prism.languages.insertBefore("javascript", "operator", {
      spread: {
        pattern: /\.{3}/,
        alias: "operator"
      },
      arrow: {
        pattern: /=>/,
        alias: "operator"
      }
    });
    Prism.languages.insertBefore("javascript", "punctuation", {
      "property-access": {
        pattern: withId(/(\.\s*)#?<ID>/.source),
        lookbehind: true
      },
      "maybe-class-name": {
        pattern: /(^|[^$\w\xA0-\uFFFF])[A-Z][$\w\xA0-\uFFFF]+/,
        lookbehind: true
      },
      dom: {
        // this contains only a few commonly used DOM variables
        pattern: /\b(?:document|location|navigator|performance|(?:local|session)Storage|window)\b/,
        alias: "variable"
      },
      console: {
        pattern: /\bconsole(?=\s*\.)/,
        alias: "class-name"
      }
    }); // add 'maybe-class-name' to tokens which might be a class name

    var maybeClassNameTokens = ["function", "function-variable", "method", "method-variable", "property-access"];

    for (var i = 0; i < maybeClassNameTokens.length; i++) {
      var token = maybeClassNameTokens[i];
      var value = Prism.languages.javascript[token]; // convert regex to object

      if (Prism.util.type(value) === "RegExp") {
        value = Prism.languages.javascript[token] = {
          pattern: value
        };
      } // keep in mind that we don't support arrays


      var inside = value.inside || {};
      value.inside = inside;
      inside["maybe-class-name"] = /^[A-Z][\s\S]*/;
    }
  })(Prism);
}

export default exports;